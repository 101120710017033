/**
 * @author - Peter Collins <https://github.com/onePeterCollins>
 * @description - Client web application for African themed culinary reality TV show
 * @file - Root application
 * @see - "./index.js"
 **/



// code splitting & lazy loading
import { Suspense } from 'react';

// app loader
import AppLoader from './components/app-loader';

// internal state
import { useContext, useEffect, useReducer, useState } from "react";

// routing
import { Navigate, useLocation } from 'react-router-dom';

// shared state
import { AppContext, REDUCER } from './utils/store';

// routes
import { ROUTES } from './utils/constants';

// navigation guard
import NavGuard from './utils/router';

// app error boundary
import AppErrorBoundary from './utils/error-boundaries/app-error-boundary';



const App = (props) => {
  const APPSTORE = useContext(AppContext);
  
  // initialize global app state
  const [ state, dispatch ] = useReducer(REDUCER, APPSTORE);

  // triggered whenever auth state changes
  const authState = state.isLoggedIn;

  useEffect (() => {
    // setAuthState(false);
  }, [authState]);

  // triggered whenever admin auth state changes
  const { adminAuth } = state;

  useEffect (() => {
    // setAuthState(false);
  }, [adminAuth]);

  // scroll to top each time user changes location
  const location = useLocation();

  useEffect (() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [location]);
  
  // redirect to location if required authentication is not found
  const [redirectLocation, setRedirectLocation] = useState('login');

  // check route validity
  const validRoute = () => {
    let valid = false;

    ROUTES.forEach((item) => {
      if (item.path.split('/')[1] === location.pathname.split('/')[1] || location.pathname === 'not-found') {
        valid = true;
      }
    });

    return valid;
  }

  // check user access
  const access = () => {
    let valid = true;

    ROUTES.forEach((item) => {
      if (item.path.split('/')[1] === location.pathname.split('/')[1]) {
        if (item.path.split('/')[1] === 'user' && !authState) {
          valid = false;

          if (redirectLocation !== 'login') {
            setRedirectLocation('login');
          }
        } else if (item.path.split('/')[1] === 'admin' && !adminAuth) {
          valid = false;

          if (redirectLocation !== 'admin-login') {
            setRedirectLocation('admin-login');
          }
        }
      }
    });

    return valid;
  }
  
  
  return (
    <div className="App">
      <Suspense fallback={AppLoader()}>
        <AppErrorBoundary scope="App">
          <AppContext.Provider value={{state, dispatch}}>

            {/* catch all route */}
            {!validRoute() && window.location.pathname.split('/')[1] !== 'not-found' && <Navigate replace to="not-found" />}

            {/* access control redirection */}
            {!access() && <Navigate replace to={redirectLocation} />}

            {/* visual content */}
            <NavGuard routes={ROUTES} auth={authState} adminAuth={authState} />
          </AppContext.Provider>
        </AppErrorBoundary>
      </Suspense>
    </div>
  );
}

export default App;
