/**
 * @file     - Global routing schema
 * @author   - Peter Collins <https://github.com/onePeterCollins>
 * @see      - "../index.js"
 **/



// lazy components
import { lazy } from 'react';

// implement route level code splitting
// common pages
const Home = lazy(() => import('../../../pages/common/landing'));
const About = lazy(() => import('../../../pages/common/about'));
const Contact = lazy(() => import('../../../pages/common/contact'));
const Contestant = lazy(() => import('../../../pages/common/contestant'));
const Contestants = lazy(() => import('../../../pages/common/contestants'));
const Faq = lazy(() => import('../../../pages/common/faq'));
const Judges = lazy(() => import('../../../pages/common/judges'));
const TermsAndConditions = lazy(() => import('../../../pages/common/terms-and-conditions'));

// auth pages
const CreatePassword = lazy(() => import('../../../pages/auth/create-password'));
const ConfirmEmail = lazy(() => import('../../../pages/auth/confirm-email'));
const ForgotPassword = lazy(() => import('../../../pages/auth/forgot-password'));
const Login = lazy(() => import('../../../pages/auth/login'));
const Register = lazy(() => import('../../../pages/auth/register'));

// user pages
const UserDashboard = lazy(() => import('../../../pages/user/dashboard'));
const UserHome = lazy(() => import('../../../pages/user/home'));
const UserNotification = lazy(() => import('../../../pages/user/notification'));
const UserNotifications = lazy(() => import('../../../pages/user/notifications'));
const UserUpdateProfile = lazy(() => import('../../../pages/user/update-profile'));
const UserVotes = lazy(() => import('../../../pages/user/votes'));

// admin pages
const AdminDashboard = lazy(() => import('../../../pages/admin/dashboard'));
const AdminHome = lazy(() => import('../../../pages/admin/home'));
const AdminInbox = lazy(() => import('../../../pages/admin/inbox'));
const AdminMessage = lazy(() => import('../../../pages/admin/message'));
const AdminCompose = lazy(() => import('../../../pages/admin/compose'));
const AdminStats = lazy(() => import('../../../pages/admin/stats'));
const AdminTransaction = lazy(() => import('../../../pages/admin/transaction'));
const AdminTransactions = lazy(() => import('../../../pages/admin/transactions'));

// admin auth pages
const AdminCreateAccount = lazy(() => import('../../../pages/admin/auth/create-admin-account'));
const AdminCreatePassword = lazy(() => import('../../../pages/admin/auth/create-password'));
const AdminForgotPassword = lazy(() => import('../../../pages/admin/auth/forgot-password'));
const AdminLogin = lazy(() => import('../../../pages/admin/auth/login'));

// 404
const NotFound = lazy(() => import('../../../pages/common/404'));



const ROUTES = [
  /* COMMON ROUTES */
  {
    name: 'home',
    component: Home,
    path: '/',
    access: 'common',
    icon: '',
    navLink: true,
    children: []
  },
  {
    name: 'about',
    component: About,
    path: '/about',
    access: 'common',
    icon: '',
    navLink: true,
    children: []
  },
  // {
  //   name: 'contestants',
  //   component: Contestants,
  //   path: '/contestants',
  //   access: 'common',
  //   icon: '',
  //   navLink: true,
  //   children: []
  // },
  {
    name: 'judges',
    component: Judges,
    path: '/judges',
    access: 'common',
    icon: '',
    navLink: true,
    children: []
  },
  {
    name: 'faq',
    component: Faq,
    path: '/faq',
    access: 'common',
    icon: '',
    navLink: true,
    children: []
  },
  {
    name: 'contact',
    component: Contact,
    path: '/contact',
    access: 'common',
    icon: '',
    navLink: true,
    children: []
  },
  {
    name: 'contestant',
    component: Contestant,
    path: '/contestant',
    access: 'common',
    icon: '',
    navLink: false,
    children: []
  },
  {
    name: 'terms',
    component: TermsAndConditions,
    path: '/terms-and-conditions',
    access: 'common',
    icon: '',
    navLink: true,
    children: []
  },

  /* AUTH ROUTES */
  {
    name: 'create-password',
    component: CreatePassword,
    path: '/create-password',
    access: 'common',
    icon: '',
    navLink: false,
    children: []
  },
  {
    name: 'confirm-email',
    component: ConfirmEmail,
    path: '/confirm-email/:id',
    access: 'common',
    icon: '',
    navLink: false,
    children: []
  },
  {
    name: 'forgot-password',
    component: ForgotPassword,
    path: '/forgot-password',
    access: 'common',
    icon: '',
    navLink: false,
    children: []
  },
  // {
  //   name: 'login',
  //   component: Login,
  //   path: '/login',
  //   access: 'common',
  //   icon: '',
  //   navLink: true,
  //   children: []
  // },
  // {
  //   name: 'register',
  //   component: Register,
  //   path: '/register',
  //   access: 'common',
  //   icon: '',
  //   navLink: true,
  //   children: []
  // },

  /* USER ROUTES */
  {
    name: 'user-dashboard',
    component: UserDashboard,
    path: '/user/:id',
    access: 'auth',
    icon: '',
    navLink: false,
    children: [
      {
        name: 'home',
        component: UserHome,
        path: '/home',
        access: 'auth',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'notification',
        component: UserNotification,
        path: '/notification/:id',
        access: 'auth',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'notifications',
        component: UserNotifications,
        path: '/notifications',
        access: 'auth',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'update-profile',
        component: UserUpdateProfile,
        path: '/update-profile',
        access: 'auth',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'votes',
        component: UserVotes,
        path: '/votes',
        access: 'auth',
        icon: '',
        navLink: false,
        children: []
      },
    ]
  },

  /* ADMIN DASHBOARD */
  {
    name: 'admin',
    component: AdminDashboard,
    path: '/admin/:id',
    access: 'admin',
    icon: '',
    navLink: false,
    children: [
      {
        name: 'home',
        component: AdminHome,
        path: '/home',
        access: 'admin',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'inbox',
        component: AdminInbox,
        path: '/inbox',
        access: 'admin',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'message',
        component: AdminMessage,
        path: '/message/:id',
        access: 'admin',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'compose',
        component: AdminCompose,
        path: '/compose',
        access: 'admin',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'stats',
        component: AdminStats,
        path: '/stats',
        access: 'admin',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'transaction',
        component: AdminTransaction,
        path: '/transaction/:id',
        access: 'admin',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'transactions',
        component: AdminTransactions,
        path: '/transactions',
        access: 'admin',
        icon: '',
        navLink: false,
        children: []
      },
      {
        name: 'create-admin-account',
        component: AdminCreateAccount,
        path: '/create-admin-account',
        access: 'admin',
        icon: '',
        navLink: false,
        children: []
      },
    ]
  },

  /* ADMIN AUTH ROUTES */
  {
    name: 'admin-create-password',
    component: AdminCreatePassword,
    path: '/admin-create-password',
    access: 'common',
    icon: '',
    navLink: false,
    children: []
  },
  {
    name: 'admin-forgot-password',
    component: AdminForgotPassword,
    path: '/admin-forgot-password',
    access: 'common',
    icon: '',
    navLink: false,
    children: []
  },
  {
    name: 'admin-login',
    component: AdminLogin,
    path: '/admin-login',
    access: 'common',
    icon: '',
    navLink: false,
    children: []
  },

  /* CATCH ALL ROUTE */
  {
    name: 'not-found',
    component: NotFound,
    path: '*',
    access: 'common',
    icon: '',
    navLink: false,
    children: []
  },
]

export default ROUTES;
