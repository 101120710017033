/**
 * @author - Peter Collins <https://github.com/onePeterCollins>
 * @description - Client web application for African themed culinary reality TV show
 * @file - Root application
 * @see - "./index.js"
 **/



// css
import styles from './index.module.css';

// loading animation
import loadingAnimation from '../../assets/images/loading-animation/loader.gif';

const AppLoader = () => {
  return (
    <div className='page grid items-center justify-center'>
      <img src={loadingAnimation} alt='loading animation' style={{width: '10rem', height: '10rem'}} />
    </div>
  )
}

export default AppLoader
