/**
 * @file     - Reducer for updating app state
 * @author   - Peter Collins <https://github.com/onePeterCollins>
 * @see      - "./index.js"
 **/

import { persistState } from "../functions";

const REDUCER = (state, action) => {
  const UpdatePersistentAppState = persistState(state);

  switch (action.type) {
    case "UPDATE_AUDITION_STATE":
      return UpdatePersistentAppState("auditionStarted", action?.value);

    case "UPDATE_ADMIN_AUTH":
      return UpdatePersistentAppState("adminAuth", action?.value);

    case "UPDATE_NAV_STATE":
      return UpdatePersistentAppState("navOpen", action?.value);

    case "UPDATE_LOGIN_STATE":
      return UpdatePersistentAppState("isLoggedIn", action?.value);

    case "UPDATE_USER":
      return UpdatePersistentAppState("user", action?.value);

    case "UPDATE_CONTESTANT_ID":
      return UpdatePersistentAppState("contestantId", action?.value);

    case "UPDATE_CONTESTANTS":
      return UpdatePersistentAppState("contestants", action?.value);

    case "UPDATE_FORM_DATA":
      return UpdatePersistentAppState("formData", action?.value);

    case "UPDATE_JUDGES":
      return UpdatePersistentAppState("judges", action?.value);

    case "UPDATE_TRANSACTION":
      return UpdatePersistentAppState("transaction", action?.value);

    case "UPDATE_NOTIFICATION_ID":
      return UpdatePersistentAppState("notificationId", action?.value);

    case "UPDATE_TOAST":
      return UpdatePersistentAppState("toast", action?.value);

    default:
      alert("Error updating");
      return;
  }
};

export default REDUCER;
