import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';



const NavGuard = (props) => {
  const { prefix, routes } = props;

  return (
    <Routes>
      {routes.map((item, index) => {
        return (
          <Fragment key={index}>
            {/* render common component */}
            {
              item.access === "common" && <Route exact path={item.path} element={<item.component name={`${prefix ? prefix + ' |': ''} ${item.name}`} />} /> 
            }

            {/* if route is auth protected, require auth */}
            {
              item.access === "auth" && props.auth ?
                <Route exact path={item.path} element={<item.component name={`${prefix ? prefix + ' |': ''} ${item.name}`} />} />
              :
                null
            }

            {/* if route is admin protected, require admin auth */}
            {
                item.access === "admin" && props.adminAuth ?
                  <Route exact path={item.path} element={<item.component name={`${prefix ? prefix + ' |': ''} ${item.name}`} />} />
                :
                  null
            }
          </Fragment>
        )})
      }
    </Routes>
  )
}

export default NavGuard;
