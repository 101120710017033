/**
 * @file     - Collection of utility functions for modularity
 * @author   - Peter Collins <https://github.com/onePeterCollins>
 * @see      - "../store/reducer.js"
 **/



const animateScroll = (obj) => {
  const { element, xPos, yPos, interval } = obj;
  const deltaX = xPos > element.scrollLeft ? (xPos - element.scrollLeft) / (interval * 60) : (element.scrollLeft - xPos) / (interval * 60);
  const deltaY = yPos > element.scrollTop ? (yPos - element.scrollTop) / (interval * 60) : (element.scrollTop - yPos) / (interval * 60);
  let animationId;

  (function animate () {
    animationId = requestAnimationFrame(animate);

    if (xPos !== undefined && Math.round(element.scrollLeft) !== Math.round(xPos)) {
      if (xPos > element.scrollLeft) {
        if (xPos - element.scrollLeft < deltaX) {
          element.scrollTo(xPos, element.scrollTop);
        } else {
          element.scrollTo(Math.round(element.scrollLeft) + deltaX, element.scrollTop);
        }
      } else {
        if (element.scrollLeft - xPos < deltaX) {
          element.scrollTo(xPos, element.scrollTop);
        } else {
          element.scrollTo(Math.round(element.scrollLeft) - deltaX, element.scrollTop);
        }
      }
    }

    if (yPos !== undefined && Math.round(element.scrollTop) !== Math.round(yPos)) {
      if (yPos > element.scrollTop) {
        if (yPos - element.scrollTop < deltaY) {
          element.scrollTo(element.scrollLeft, yPos);
        } else {
          element.scrollTo(element.scrollLeft, Math.round(element.scrollTop) + deltaY);
        }
      } else {
        if (element.scrollTop - yPos < deltaY) {
          element.scrollTo(element.scrollLeft, yPos);
        } else {
          element.scrollTo(element.scrollLeft, Math.round(element.scrollTop) - deltaY);
        }
      }
    }

    if ((Math.round(element.scrollLeft) === Math.round(element.scrollWidth - element.offsetWidth) || Math.round(element.scrollLeft) === Math.round(xPos)) && (Math.round(element.scrollTop) === Math.round(element.scrollHeight - element.offsetHeight) || Math.round(element.scrollTop) === Math.round(yPos))) {
      cancelAnimationFrame(animationId);
    }
  })()
}

const capitalize = (string) => {
  const splitString = string.split('');
  let value = '';

  splitString.forEach((item, index) => {
    if (index === 0) {
      value += item.toUpperCase();
    } else if ((splitString[index - 1] === ' ' || splitString[index - 1] === '-') && splitString[index] !== ' ') {
      value += item.toUpperCase();
    } else {
      value += item
    }
  })

  return value;
}

/**
 * @param element => element: HTML DOM element
 * @param property => property: String
 */
 const getComputedStyles = (element, property) => {
  let value
  let style
  if (window.getComputedStyle) {
      style = window.getComputedStyle(element).getPropertyValue(property)
  } else if (document.defaultView) {
      style = document.defaultView(element).getPropertyValue(property)
  }

  // TEST WHETHER THE STYLE IS A STRING OR NUMBER VALUE
  let p = style.split('').reverse().splice(1, 1)  // check if the value ends with 'PX'
  let x = style.split('').reverse().shift(0)  // check if the value ends with 'PX'
  let num = style.split('').reverse().splice(2, 1).join('') * 1

  // eslint-disable-next-line
  if (p == 'p' && x === 'x' && typeof (num) === 'number') {
      value = parseInt(style, 10)
  } else {
      value = style
  }
  return value
}

// I use this to persist current state when user refreshes the page
const persistState = (state, field, newVal) => {
  return (_field = field, _newVal = newVal) => {
    state[_field] = _newVal;
    sessionStorage.setItem('AppState', JSON.stringify(state));
    return JSON.parse(sessionStorage.getItem('AppState'));
  }
}

export {
  animateScroll,
  capitalize,
  getComputedStyles,
  persistState,
}
