/**
 * @file     - Lightweight persisent global data store using context API
 * @author   - Peter Collins <https://github.com/onePeterCollins>
 * @see      - "../../App.js"
 **/



import { createContext } from "react";
import REDUCER from "./reducer";

const NewState = {
  auditionStarted: false,
  adminAuth: false,
  navOpen: false,
  isLoggedIn: false,
  user: null,
  contestantId: '',
  contestants: [],
  formData: {},
  judges: [],
  transaction: '',
  notificationId: '',
  toast: null
}

const PersistentState = JSON.parse(sessionStorage.getItem('AppState'));

const AppState = PersistentState ? PersistentState : NewState;

const AppContext = createContext(AppState);

export {
  AppContext,
  REDUCER
}
