/**
 * @author - Peter Collins <https://github.com/onePeterCollins>
 * @description - App error boundary
 * @file - Displays error page and sends an error report if app crashes in production
 * @see - "../../../App.js"
 **/

// component
import { Component } from "react";

// css
import styles from "./index.module.css";

const AppErrorBoundary = class extends Component {
  constructor (props) {
    super(props);

    this.state = {
      scope: this.props.scope ? this.props.scope : undefined,
      error: null
    }
  }

  componentDidMount () {
    // this.setState({error: true});
  }

  componentDidUpdate () {
      
  }

  componentDidCatch (error) {
    this.setState({error: error});
    console.log('from App error boundary');
    console.log(error);
    localStorage.setItem('error', error);
  }

  render () {
    const Children = this.props.children;

    const renderError = () => (
      <div className='d-flex fd-column w-100 px-6 justify-center align-center background' style={{height: '100vh'}}>
        <p className='hero-text error--text'>Fatal Error!</p>
        <p className='regular-text mb-3'>Sorry, an unexpected error occured that caused the app to crash.</p>
        <p className='small-text'>In the meantime you can try refreshing the page</p>

        <div className='d-flex w-100 m-6 justify-space-around'>
          <button className={`${styles.btn} primary`}>
            <a className='w-100 h-100 regular-text font--text' href='https://admin.ideyfind.com' target='_blank' rel='noreferrer'>Report Issue</a>
          </button>

          <button className={`${styles.btn} regular-text secondary`} onClick={() => window.location.reload()}>Refresh</button>
        </div>
      </div>
    )

    return (
      <>
        {this.state.error ?
          renderError()
        :
          Children
        }
      </>
    )
  }
}

export default AppErrorBoundary;
